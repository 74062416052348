<template>
  <div>
    <create
      :is-add-new-page-sidebar-active.sync="isShowFormAdd"
      :is-loading="isLoading"
      :type-form="typeForm"
      :data="discountData"
      @onSubmit="onSubmit"
    />
    <b-card>
      <own-table
        ref="discountTable"
        name-table="vouchers"
        :table-columns="columns"
        :use-custom-filter="true"
        :custom-query-filter="queryFilter"
        @searchFieldOnChange="searchOnChange"
        sort-by="created_at"
        sort-direction="desc"
        @evt-add="$router.push({ name: 'apps-create-discount' })"
      >
        <template #cell(no)="data">
          <span>{{ data.data.index + 1 }}</span>
        </template>
        <template #cell(is_active)="data">
          <b-badge :variant="statusVariant(data.data.item.is_active)">
            <span>{{
              data.data.item.is_active === 1 ? 'Aktif' : 'Tidak Aktif'
            }}</span>
          </b-badge>
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.data.item.created_at) }}</span>
        </template>
        <template #cell(start_date)="data">
          <span>{{ formatDate(data.data.item.start_date) }}</span>
        </template>
        <template #cell(expire_date)="data">
          <!-- <span>{{ moment(data.data.item.expire_date).format('DD-MM-YYYY') }}</span> -->
          <span>{{ formatDate(data.data.item.expire_date) }}</span>
        </template>
        <template #cell(value)="data">
          <span>{{
            data.data.item.type === 'fixed' ||
            data.data.item.type === 'shipping'
              ? formatCurrency(data.data.item.value)
              : `${data.data.item.value}%`
          }}</span>
        </template>
        <template #cell(min_transaction_value)="data">
          <span>{{
            formatCurrency(data.data.item.min_transaction_value)
          }}</span>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              menu-class="position-absolute"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="setStatus(data.data.item)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">{{
                  data.data.item.is_active === 1
                    ? 'Set Tidak Aktif'
                    : 'Set Aktif'
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  $router.push({
                    name: 'apps-show-discount',
                    params: { id: data.data.item.id },
                  })
                "
              >
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="onDelete(data.data.item.id)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import { BCard, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import store from '@/store'
import { formatDate, formatCurrency } from '@/@core/utils/filter'
import Create from './components/Create.vue'
import discountStoreModule from './discountStoreModule'

export default {
  components: {
    BCard,
    OwnTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    Create,
  },
  setup() {
    const DISCOUNT_STORE_MODULE = 'vouchers'
    if (!store.hasModule(DISCOUNT_STORE_MODULE)) {
      store.registerModule(DISCOUNT_STORE_MODULE, discountStoreModule)
    }
    onUnmounted(() => {
      store.unregisterModule(DISCOUNT_STORE_MODULE)
    })
    const discountTable = ref(null)
    const columns = ref([
      {
        label: 'No',
        key: 'no',
      },
      {
        label: 'Kode Voucher',
        key: 'code',
        sortable: true,
      },
      {
        label: 'Deskripsi',
        key: 'description',
        sortable: true,
      },
      {
        label: 'Nilai',
        key: 'value',
        sortable: true,
      },
      {
        label: 'MIn. Transaksi',
        key: 'min_transaction_value',
        sortable: true,
      },
      {
        label: 'Batas Redem',
        key: 'limit_number_redemptions',
        sortable: true,
      },
      {
        label: 'Tanggal Berlaku',
        key: 'start_date',
        sortable: true,
      },
      {
        label: 'Tanggal Berakhir',
        key: 'expire_date',
        sortable: true,
      },
      {
        label: 'Status',
        key: 'is_active',
        sortable: true,
      },
      {
        label: 'Created At',
        key: 'created_at',
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const isLoading = ref(false)
    const isShowFormAdd = ref(false)
    const queryFilter = ref('')
    const search = ref('')
    const typeForm = ref('add')
    const discountData = ref(null)
    const onSubmit = formData => {
      isLoading.value = true
      discountTable.value.submit(formData, isSuccess => {
        if (isSuccess) {
          isShowFormAdd.value = false
        }
        isLoading.value = false
      })
    }

    const statusVariant = status => {
      if (status === 1) {
        return 'success'
      }
      return 'danger'
    }

    const showAddForm = () => {
      isShowFormAdd.value = true
      discountData.value = null
    }

    const searchOnChange = value => {
      queryFilter.value = `&q=${value}`
    }

    const setStatus = data => {
      const form = data
      form.is_active = data.is_active === 1 ? 0 : 1
      discountTable.value.onEdit()
      discountTable.value.submit(form, () => {})
    }

    const edit = data => {
      typeForm.value = 'edit'
      discountTable.value.onEdit()
      discountData.value = data
      isShowFormAdd.value = true
    }

    const onDelete = id => {
      discountTable.value.onDelete(id)
    }
    // const formatDateTime = date => formatDate(date)
    return {
      columns,
      queryFilter,
      discountTable,
      statusVariant,
      formatDate,
      formatCurrency,

      showAddForm,
      isShowFormAdd,

      searchOnChange,

      onSubmit,

      isLoading,
      setStatus,
      edit,
      discountData,
      typeForm,
      onDelete,
    }
  },
}
</script>
