import axios from '@axios'
import crypto from 'crypto'
import { Base64 } from 'js-base64'
import CryptoJS from 'crypto-js'
import Vue from 'vue'

const vm = new Vue()

function aesEncrypt(txt) {
	const iv = crypto.randomBytes(8).toString('hex')
	const cipher = CryptoJS.AES.encrypt(
		txt,
		CryptoJS.enc.Utf8.parse('82f2ceed4c503896c8a291e560bd4325'),
		{
			iv: CryptoJS.enc.Utf8.parse(iv),
			mode: CryptoJS.mode.CBC,
		},
	)

	const ret = [cipher.toString(), iv]

	return Base64.encode(ret)
}
export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		setStatus(ctx, { id, status }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`api/auth/calon-agen/${id}/setStatus`, {
						status,
					})
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		productOnSearch(ctx, { search }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`api/auth/produk?sort_by=nama_produk&direction=asc&q=${search}`)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
	},
}
